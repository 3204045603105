import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../users/users.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ReferrerInterface } from './referrers.interface';
import { SharedService } from 'src/app/components/shared-service/shared.service';

declare const fbq: any;

@Component({
  selector: 'app-referrers',
  templateUrl: './referrers.component.html',
  styleUrls: ['./referrers.component.css'],
  providers: []
})

export class ReferrersComponent implements OnInit {
  // _env: string = this.env._env;
  referredInfo: any;
  referrerCode: string;
  routeSub: Subscription;
  IReferrer: ReferrerInterface;
  
  constructor(private userService: UserService, private route: ActivatedRoute, private router: Router, private sharedService: SharedService) {
  }

  async ngOnInit() {
    this.routeSub = this.route.params.subscribe(params => {
      this.referrerCode = params['id']
      this.sharedService.setVariable(this.referrerCode);
    });

    const referredInfo = await this.userService.getReferredInfo(this.referrerCode);
    this.IReferrer = referredInfo['referred_info'];
  }

  goToQuoter(): void {
    this.userService.addMixPanelReferredEvent('Venta Autónoma Referidos', { referidor: this.IReferrer['email'] });
    this.router.navigate([`/referido/` + this.referrerCode]);
  }

  goToLanding(): void {
    window.location.href = 'https://contalink.com/contacto/?referrer_code=' + this.referrerCode;
  }
}