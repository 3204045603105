import { Component, OnInit, ViewChild, Renderer2, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { PREMIUM_LIST, PROFESIONAL_LIST, PIPEDRIVE_REFERENCE, LINE_OF_BUSINESS_LIST } from '../../users/list.data';
import { IPlanInfo } from 'src/app/interfaces/plan-info.interface';
import { UserService } from '../../users/users.service';
import { FormControl, FormGroup, FormBuilder, Validators, FormsModule } from '@angular/forms';
import { EnvironmentService } from 'src/app/services/environment.service';
import { PostSlackService } from '../../../services/post-slack.service';
import { Router } from '@angular/router';
import { SweetAlertService } from 'src/app/services/sweet-alert.service';
import { RfcModalService } from 'src/app/services/rfc-modal.service';
import { loadStripe } from '@stripe/stripe-js/pure';

declare const fbq: any;

@Component({
  selector: 'app-step-one-quoter',
  templateUrl: './step-one-quoter.component.html',
  styleUrls: ['./step-one-quoter.component.css']
})
export class StepOneQuoterComponent implements OnInit {

  constructor(private renderer: Renderer2, private userService: UserService,
    public PostSlack: PostSlackService,
    private router: Router,
    private sweetAlert: SweetAlertService,
    private rfcModalService: RfcModalService,
    private env: EnvironmentService,
    private _formBuilder: FormBuilder
  ) {
  }

  processingPayment: boolean = false;
  selectedPlanOption: string = 'PREMIUM';
  selectedPaymentOption: string = 'MENSUAL';
  formatter: any;
  is_valid: boolean = true;
  acceptedPromotionalEmail: boolean = true;
  acceptedTyC: boolean = false
  fieldTextType: boolean = false;

  selectLineOfBusinessId: string = null;
  selectedRegimen: string = null;
  selectedRegimenName: string = null;
  selectedRecurrence: string = null;
  selectedFuenteName: string = null;
  selectedFuente: string = null;
  stepTwoCompleted: boolean = false;

  // ONBOARDING VARIABLES
  obAgentName: string;
  obAgentEmail: string;
  obAgentPhone: string;

  textToolTipPhone = "10 dígitos sólo números";

  pipedriveReference: string = '';
  hashSession: string;

  emailCodeCorrect: boolean = false;
  slack_submitted: boolean = false;

  paymentElement: any;
  paymentMethod: string = null;
  spei_data: any;

  get secondFormControl() {
    return this.secondFormGroup.controls;
  }


  public secondFormGroup: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    phone: new FormControl('', [Validators.required, Validators.nullValidator]),
    email: new FormControl('', [Validators.required, Validators.nullValidator]),
    username: new FormControl('', [Validators.required, Validators.nullValidator]),
    userpass: new FormControl('', [Validators.required, Validators.pattern("^.{6,}$")]),
    company: new FormControl('', [Validators.required, Validators.nullValidator]),
    rfc: new FormControl('', [Validators.required, Validators.nullValidator]),
    company_invoicing: new FormControl('', [Validators.required, Validators.nullValidator]),
    rfc_invoicing: new FormControl('', [Validators.required, Validators.nullValidator]),
    use_same_data_for_invoicing: new FormControl('', [Validators.required, Validators.nullValidator]),
    regimen_fiscal: new FormControl('', [Validators.required, Validators.nullValidator]),
    fuente: new FormControl('', [Validators.required, Validators.nullValidator]),
    postal_code: new FormControl('', [Validators.required, Validators.nullValidator]),
  })

  _env: string = this.env._env;
  url_path: string = window.location.href;

  // INPUTS VARIABLES
  @Input() plan: IPlanInfo;
  @Input() plans: IPlanInfo[];
  @Input() lite_code: string;
  @Input() is_lite: boolean;
  @Input() liteInfo: any;
  @Input() referred_id: any = null;
  @Input() referredName: any;
  @Input() referredEmail: any;
  @Input() stateName: any;

  // OUTPUTS VARIABLES
  @Output() executeGoToStepTwo: EventEmitter<void> = new EventEmitter();
  @Output() changePlan: EventEmitter<IPlanInfo> = new EventEmitter();

  // VIEW CHILD
  @ViewChild('list_features_plan') listFeaturesPlanElement!: ElementRef<HTMLUListElement>;
  // TOTALS
  @ViewChild('totals_block_one_label_subtotal2') totalsBlockOneLabelSubtotal!: ElementRef<HTMLLabelElement>;
  @ViewChild('totals_block_one_value_subtotal2') totalsBlockOneValueSubtotal!: ElementRef<HTMLLabelElement>;
  @ViewChild('number_of_users') numberOfUsersRef!: ElementRef<HTMLInputElement>;

  // FORMS 
  @ViewChild('phone') phoneElement!: ElementRef;
  @ViewChild('rfc') rfcElement!: ElementRef;
  @ViewChild('email') emailElement!: ElementRef;

  // LISTS
  PremiumList: string[] = [];
  ProfesionalList: string[] = [];
  pipedriveReferenceList: any = null;
  lineOfBusinessList: any = null;

  // COOKIES
  utm_medium: string;
  utm_source: string;
  utm_campaign: string;
  utm_term: string;
  utm_content: string;
  fbclid: string;
  gclid: string;
  ttclid: string;
  utm_id: string;
  version: string;
  ad_set: string;
  first_utm: string;
  first_visit_date: string;

  // Stripe
  elements: any;
  stripe: any;
  stripe_api_key: string;
  stripe_secret_key: string;
  client_secret: string;
  COOKIES_EXPIRATON_DAYS = 365

  check_company: Promise<any> = null;

  public signupForm: FormGroup = new FormGroup({
    type_plan: new FormControl('', [Validators.required, Validators.nullValidator]),
    users_number: new FormControl('', [Validators.required, Validators.nullValidator])
  });

  public firstFormGroup: FormGroup = new FormGroup({
  });

  // FREE TRIAL
  free_trial = false;

  async ngOnInit(): Promise<void> {
    this.PremiumList = await PREMIUM_LIST;
    this.ProfesionalList = await PROFESIONAL_LIST;
    this.pipedriveReferenceList = PIPEDRIVE_REFERENCE;
    this.lineOfBusinessList = LINE_OF_BUSINESS_LIST;

    this.secondFormGroup.get('username').disable();
    // Validate the username and password fields when in lite mode
    this.validateUserAndPasswordInLite();

    this.signupForm.get('users_number').setValue(1);

    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required],
    });

    this.secondFormGroup = this.buildSecondFormGroup();

    this.hashSession = this.userService.makeHashSession();

    if (this.stateName !== 'Michoacán') {
      this.assignTotalPrice();
      this.changePriceByPlansSelected();
    }

    this.displayInfoPlan();
  }

  private validateUserAndPasswordInLite(): void {
    if (this.lite_code != null && this.lite_code != undefined) {
      this.secondFormGroup.get('email').disable();
      this.secondFormGroup.get('userpass').disable();
      this.secondFormGroup.get('username').disable();
    }
  }

  async MichoacanPostSlack(): Promise<void> {
    this.processingPayment = true;
    let validateAllFieldsJSON = this.userService.validateAllFields(
      this.secondFormGroup, this.selectedRegimen, this.acceptedTyC,
      true // IS MICHOACAN
    )

    // VALIDAR CAMPOS PARA FORMULARIO DE MICHOACAN
    if (validateAllFieldsJSON.message === undefined) { validateAllFieldsJSON.message = ''; }
    validateAllFieldsJSON.message += this.validatePipedriveReference();
    if (this.stateName === 'Michoacán') validateAllFieldsJSON.message += this.validateLineOfBusiness();
    if (validateAllFieldsJSON.message !== '') { validateAllFieldsJSON.status = false; }


    if (!validateAllFieldsJSON.status) {
      this.userService.addLogTransaction("form_validation " + validateAllFieldsJSON.message, null, this.hashSession);
      alert(validateAllFieldsJSON.message)
      this.processingPayment = false;
      return null;
    }
    // NUMERO DE USUARIOS SIEMPRE SERA 1 CUANDO ES MICHOACAN

    this.plan.number_of_users = 1;

    this.plan.potential_user_number = Number(this.numberOfUsersRef.nativeElement.value);

    this.free_trial = true;
    let data = this.fillDataValues();

    this.check_company = await this.userService.checkRFC(
      this.secondFormGroup.get('rfc').value,
      this.secondFormGroup.get('email').value.toLowerCase(),
      this.secondFormGroup.get('phone').value,
      data
    );

    if (this.check_company['found_users'] > 0 && this.check_company['lite_user_rfc'] != null && !this.is_lite) {
      this.is_lite = true;
      data["lite_code"] = this.check_company['lite_user_rfc'] + "_lite_code";
      if (data["rfc"] == this.check_company['lite_user_rfc']) {
        data["new_rfc_lite"] = null;
      } else {
        data["new_rfc_lite"] = data["rfc"]
      }
    }

    if ((this.check_company['found_companies'] > 0 || this.check_company['found_users'] > 0 || this.check_company['found_bpartner'] > 0 || this.secondFormGroup.get('rfc').value == "XAXX010101000") && !this.is_lite) {
      this.validateAllConditions(data);
    } else {
      if ((this.check_company['found_deals']['active'] > 0 && this.check_company['found_companies'] > 0) || (this.check_company['found_deals']['bp_active'] > 0 && this.check_company['found_bpartner'] > 0)) {
        this.validateAllConditions(data);
      }
      this.postSlackMichoacan(data);
      return;
    }
  }

  private finishMichoacan(post_slack: boolean, errorTitle: string = null, errorSubtitle: string = null, onboarding_agent_info: any = null): void {
    const onboardingAgent = onboarding_agent_info ? onboarding_agent_info['onboardingagent'] : null;
    const email = onboarding_agent_info ? onboarding_agent_info['email'] : null;
    const phone = onboarding_agent_info ? onboarding_agent_info['phone'] : null;

    this.router.navigate(['/listoss'], {
      queryParams: {
        michoacan: true,
        post_slack,
        errorTitle,
        errorSubtitle,
        onboardingagent: onboardingAgent,
        email,
        phone
      }
    });
  }

  private buildSecondFormGroup(): FormGroup {
    return this._formBuilder.group({
      name: ['', Validators.required],
      phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      email: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")]],
      username: ['', Validators.required],
      userpass: ['', Validators.nullValidator],
      company: ['', Validators.required],
      rfc: ['', [Validators.required, Validators.pattern("^[A-Z,Ñ,&]{3,4}[0-9]{2}[0-1][0-9][0-3][0-9][A-Z0-9]{3}")]],
      company_invoicing: ['', [Validators.required, Validators.nullValidator]],
      rfc_invoicing: ['', [Validators.required, Validators.nullValidator, Validators.pattern("^[A-Z,Ñ,&]{3,4}[0-9]{2}[0-1][0-9][0-3][0-9][A-Z0-9]{3}")]],
      use_same_data_for_invoicing: ['', Validators.required],
      regimen_fiscal: ['', Validators.required],
      fuente: ['', Validators.required],
      postal_code: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{5}$")]]
    });
  }

  private validateExperimentAndLiteUser(): boolean {
    if (this.is_lite &&
      (this.stateName === 'Yucatán' || this.stateName === 'Michoacán')) {
      this.finishMichoacan(
        false,
        'Ya casi, solo un paso más',
        `Tu correo tiene acceso a Academia Contalink, para convertirlo a usuario un asesor se comunicará contigo. 
        Para cualquier duda puedes escribirnos a info@contalink.com o por Whatsapp al 811 910 7569`,
        null
      );

      return true;
    }

    return false;
  }

  private async postSlackMichoacan(data) {
    this.userService.addLogTransaction("post_slack_deal " + (this.secondFormControl.email.value).toLowerCase(), null, this.hashSession)
    let post_slack: any = await this.PostSlack.postContalinkSlack(
      data
    ).toPromise()

    const isExperimentLiteUser: boolean = this.validateExperimentAndLiteUser();
    if (isExperimentLiteUser) { return; }

    if (post_slack['freetrial_result'] && post_slack['freetrial_result']['error'] === true) {
      this.finishMichoacan(
        false,
        'Ya casi, solo un paso más',
        post_slack['freetrial_result']['errorMessage'],
        null
      );
    } else {
      this.finishMichoacan(
        true,
        '',
        '',
        post_slack['freetrial_result']['deal']['onboarding_info']
      );
    }
  }

  private async validateEmailExist() {
    if (this.check_company['found_users'] > 0 && !this.is_lite) {
      const response: any = await this.validateExistsEmail();
      if (response) {
        this.emailCodeCorrect = true;
        return true;
      } else {
        // this.stepTwoReadMode(false)
        this.slack_submitted = false
        this.userService.addLogTransaction("validation_existing_user", null, this.hashSession);
        this.stepTwoCompleted = false;

        return false;
      }
    }
    return true;
  }

  private async validateExistsEmail() {
    const response = await this.sweetAlert.codeSweetAlert(this.secondFormGroup.get('email').value.toLowerCase());
    return response;
  }

  validateProPremPlusUser(): boolean {
    let userPlanLevel: number;
    let isUserAcademy: boolean;
    let userHasDealId: boolean;

    if (this.check_company['userPlan']) {
      userPlanLevel = this.check_company!['userPlan']!['userLevelPlan'];
      isUserAcademy = this.check_company!['userPlan']!['userIsAcademy'] == null ? false : true;
      userHasDealId = this.check_company!['userPlan']!['userHasDealId'] == null ? false : true;
    } else {
      return false;
    }

    // SI TIENE ASIGNADO UN DEAL EN UC_DEAL ENTRA AL CONDICIONAL
    if (userHasDealId) {
      if ((userPlanLevel < 1 && userPlanLevel > 3) || this.is_lite || isUserAcademy || this.check_company!['userPlan']['despacho_id'] == 18712) {
        return false;
      } else {
        // VALIDAR LO SIGUIENTE
        this.showProPremiumPlusModal();
        this.slack_submitted = false;
        this.secondFormGroup.get('email').setValue(null);
        // this.stepTwoReadMode(false);

        return true;
      }
    } else {
      return false;
    }
  }

  // PRIVATE METHODS
  validateCancelTime(): boolean {
    if ((this.check_company!['found_deals']!['cancel'] || 0) == 1) {
      const monthsCancellation = this.check_company['userCancellationMonths'];

      if (monthsCancellation < 6) {
        // mostrar mensaje
        this.sweetAlert.showAlertTimeCancellation();
        this.slack_submitted = false;
        this.secondFormGroup.get('rfc').setValue(null);
        // this.stepTwoReadMode(false);

        return true;
      } else {
        return false;
      }

    }
  }

  private showProPremiumPlusModal(): void {
    this.sweetAlert.showAlertProPremPlus();
  }

  private validateGenericRFC(rfc: string, data: any): boolean {
    if (rfc != "XAXX010101000") { return false; }

    alert("Favor de modificar el RFC de la primer empresa a utilizar en el sistema, no es posible activar con un RFC Génerico");
    data["rfc_generico"] = true;
    // this.stepTwoReadMode(false);
    this.slack_submitted = false;
    this.userService.addLogTransaction("validation_generic_tax_id", null, this.hashSession);
    this.stepTwoCompleted = false;

    return true;
  }

  private openRFCModal(entity: string, rfc: string) {
    this.rfcModalService.openModal({
      "entity": entity,
      "obAgentName": this.obAgentName,
      "obAgentEmail": this.obAgentEmail,
      "obAgentPhone": this.obAgentPhone,
      "emailInput": this.secondFormControl.email.value,
      "rfcInput": rfc
    });
  }

  private validateExistRfc(data: any): boolean {
    if (this.check_company['found_bpartner'] && !this.is_lite) {
      if ((this.check_company['found_deals']['bp_active'] || 0) > 0) {
        this.secondFormGroup.get('rfc_invoicing').setValue(null);
        this.secondFormGroup.get('rfc').setValue(null);
        //alert("En Contalink ya existe un Cliente con este RFC, cambie el RFC de facturación o en breve nos contactaremos con usted o puede escribirnos a info@contalink.com")
        data["partner_exists"] = true
        // this.stepTwoReadMode(false)
        this.slack_submitted = false
        this.userService.addLogTransaction("validation_existing_partner", null, this.hashSession);
        this.obAgentName = this.check_company['found_deals']['obagent_name'];
        this.obAgentEmail = this.check_company['found_deals']['obagent_email'];
        this.obAgentPhone = this.check_company['found_deals']['obagent_phone'];
        this.openRFCModal("partner", null)
        return true;
      }
    }
    return false;
  }

  private async validateAllConditions(data, stepper = null) {
    // // CORREO USUARIO EXISTENTE
    const isEmailExist: boolean = await this.validateEmailExist();
    if (!isEmailExist) { this.processingPayment = false; return; }

    // VALIDA SI ES UN USUARIO ACADEMIA CON EXPERIMENTO ACTIVO
    const isExperimentAcademyUser: boolean = await this.validateExperimentAndAcademyUser(data);
    if (isExperimentAcademyUser) { this.processingPayment = false; return; }

    // VALIDA SI EL USUARIO ESTA EN UN DEAL ACTIVO PRO, PLUS O PREMIUM
    const isProPremPlus: boolean = await this.validateProPremPlusUser();
    if (isProPremPlus) { this.processingPayment = false; return; }

    const isTimeLessThanSixMonths: boolean = await this.validateCancelTime();
    if (isTimeLessThanSixMonths) { this.processingPayment = false; return; }

    // // VALIDAR SI EL RFC ES GENERICO
    const isRfcGenric: boolean = this.validateGenericRFC(this.secondFormGroup.get('rfc').value, data);
    if (isRfcGenric) { this.processingPayment = false; return; }

    // // EXISTE UN CLIENTE ES CONTALINK CON ESE RFC
    const isRfcExist: boolean = this.validateExistRfc(data);
    if (isRfcExist) { this.processingPayment = false; return; }

    // // RFC CORRESPONDE A UNA CUENTA LITE
    const isRfcLite: boolean = this.validateRfcLite(data);
    if (isRfcLite) { this.processingPayment = false; return; }

    if (this.check_company['is_rfc_lite']) {
      // El correo no existe en Contalink pero el rfc ingresado existe y es lite
      // alert("El RFC ingresado corresponde a una cuenta Lite, ingresa el correo electrónico asociado a dicha cuenta para continuar.")
      this.processingPayment = false;
      return;
    }

    if (this.check_company['found_users'] <= 0) {
      // SI EL ESTADO ES MICHOACAN STEPPER LLEGA NULL Y SE MANDA EL POSTSLACK DIFERENTE
      if (stepper == null) {
        this.userService.addLogTransaction("post_slack_no_deal " + this.secondFormControl.email.value, null, this.hashSession);
        this.postSlackMichoacan(data);
      } else {
        //registrar el deal y slack sólo si no existe como usuario
        this.userService.addLogTransaction("post_slack_no_deal " + this.secondFormControl.email.value, null, this.hashSession);
        this.postSlack(data, stepper);
      }
    }
    if (this.emailCodeCorrect) {
      if (stepper == null) {
        this.postSlackMichoacan(data);
      } else {
        this.postSlack(data, stepper);
      }
    }
  }

  async selectOption(planType: string): Promise<void> {
    this.plan.number_of_users = 1;

    if (planType === 'PREMIUM') {
      this.selectedPlanOption = 'PREMIUM';
      this.changePriceByPlansSelected();
      this.displayInfoPlan();
    } else {
      this.selectedPlanOption = 'PROFESIONAL';
      this.changePriceByPlansSelected();
      this.displayInfoPlan();
    }
  }

  async selectPaymentOption(paymentType: string) {
    this.plan.number_of_users = 1;

    if (paymentType === 'ANUAL') {
      this.selectedPaymentOption = 'ANUAL';
      this.changePriceByPlansSelected();
    } else {
      this.selectedPaymentOption = 'MENSUAL';
      this.changePriceByPlansSelected();
    }
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  userCount(type: string): void {
    switch (type) {
      case 'plus': {
        if (this.plan.number_of_users <= 99) {
          this.plan.number_of_users++;
          this.changePriceByPlansSelected();
        }
        break;
      }
      case 'less': {
        if (this.plan.number_of_users >= 2) {
          this.plan.number_of_users--;
          this.changePriceByPlansSelected();
        }
        break;
      }
    }
  }

  async goStepTwoChild() {
    this.executeGoToStepTwo.emit();
  }

  onChangeLineOfBusiness() {
    let e = (document.getElementById("line_of_business")) as HTMLSelectElement;
    if (e == null) return;

    this.selectLineOfBusinessId = e.options[e.selectedIndex].dataset.id;
    this.secondFormGroup.addControl('line_of_business',
      new FormControl(this.selectLineOfBusinessId));
    this.onCapture("line_of_business");
  }

  onCapture(field) {
    if (field == 'company') {
      this.seeStepTwoDownForm()
    }
    // Validate form inputs
    this.validateSecondFormInputs(field);
    // Si los datos de facturación estan apagados entonces replicar rfc y razón social
    this.secondFormGroup.get('rfc_invoicing').setValue(this.secondFormGroup.get('rfc').value);
    this.secondFormGroup.get('company_invoicing').setValue(this.secondFormGroup.get('company').value);

    if (this.secondFormGroup.get('name').invalid || this.secondFormGroup.get('phone').invalid || this.secondFormGroup.get('email').invalid
      || this.secondFormGroup.get('company').invalid || this.secondFormGroup.get('rfc').invalid ||
      this.secondFormGroup.get('postal_code').invalid || this.selectedRegimen == null || this.selectedFuente == null ||
      this.selectLineOfBusinessId == null ||
      // this.pipedriveReference == null ||
      this.secondFormGroup.get('rfc_invoicing').invalid || this.secondFormGroup.get('company_invoicing').invalid) {

      this.stepTwoCompleted = false;
    } else {
      this.stepTwoCompleted = true;
      // this.seeStepTwoNextButton()
    }
  }

  changeAcceptedPromotionalEmail(): void {
    if (this.acceptedPromotionalEmail) this.acceptedPromotionalEmail = false;
    else this.acceptedPromotionalEmail = true;
  }

  onChangePipedriveReference(): void {
    let e = (document.getElementById("pipedrive_reference")) as HTMLSelectElement;
    if (e == null) return;

    this.pipedriveReference = e.options[e.selectedIndex].dataset.id;
    this.secondFormGroup.addControl('pipedrive_reference',
      new FormControl(this.pipedriveReference));
  }

  // Fill all values from FormGroup in Data variable
  private fillDataValues(): any {
    this.formatter = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      minimumFractionDigits: 2
    });

    const qa_env: boolean = (this._env != 'prod') ? true : false;

    return this.userService.fillDataValues(
      this.secondFormControl, qa_env, this.plan.number_of_users, (this.plan.id).toString(), (this.plan.recurrence).toString(),
      this.formatter.format(this.plan.total_sale).toString(), this.formatter.format(this.plan.discount).toString(), this.selectedRegimenName, this.selectedRegimen,
      this.selectedFuente, this.pipedriveReference, this.plan.price, this.plan.discount_deal, this.utm_medium,
      this.utm_source, this.utm_campaign, this.fbclid, this.gclid, this.ttclid, this.utm_id, this.version,
      this.utm_term, this.utm_content, this.ad_set, this.first_utm, this.first_visit_date, this.lite_code, this.referred_id,
      this.referredEmail, this.plan.half_off_discount, this.plan.volumen_discount_percentage, this.plan.annual_discount_percentage,
      this.plan.volumen_discount_amount, this.plan.annual_discount_amount,
      this.assignEmailPromotional(this.acceptedPromotionalEmail), Number(this.plan.welcome_discount_percentage), this.free_trial,
      this.plan.potential_user_number, this.selectLineOfBusinessId, false
    );
  }

  async postSlack(data, stepper) {
    // Postear Slack con Pipedrive
    this.userService.addLogTransaction("post_slack_deal " + (this.secondFormControl.email.value).toLowerCase(), null, this.hashSession)
    // let post_slack: any;
    let post_slack: any = await this.PostSlack.postContalinkSlack(
      data
    ).toPromise()

    // VALIDA SI ES UN USUARIO LITE CON EXPERIMENTO ACTIVO 
    // const isExperimentLiteUser: boolean = this.validateExperimentAndLiteUser();
    // if (isExperimentLiteUser) { return; }

    if (post_slack['ok'] == true) {
      this.client_secret = post_slack['business_partner']['stripe_clientsecret_id']
      // LandingStepPayment cuando el usuario llena su info y llega al paso M de Pago.
      this.userService.addMixPanelEvent('LandingStepPayment', { status: 'ok' });
      if (this.url_path.includes("//signup.contalink.com")) { fbq("trackCustom", "VA Registro Datos"); }
      /* Acá se carga el formulario de stripe senddata se ejecuta al terminar el segundo paso */
      this.stripe = loadStripe(this.stripe_api_key);
      const options = {
        clientSecret: this.client_secret //'{{CLIENT_SECRET}}',
      };

      this.elements = (await this.stripe).elements(options);
      this.paymentElement = this.elements.create('payment');
      this.paymentElement.mount('#payment-element');
      const form = document.getElementById('payment-form');

      var data_stripe = data
      data_stripe['business_partner_id'] = post_slack['business_partner']['business_partner_id']
      data_stripe['uc_customer_stripe_id'] = post_slack['business_partner']['stripe_customer_id']

      this.userService.addLogTransaction("stripe_form_load", null, this.hashSession)

      this.userService.addLogTransaction(
        "record_landing_write " + post_slack['landing_record']['landing_record_id'],
        post_slack['landing_record']['landing_record_id'], this.hashSession);

      stepper.next()

    } else {
      this.userService.addLogTransaction("stripe_generic_form_error", null, this.hashSession);
      this.userService.addMixPanelEvent('LandingStepThree', { status: 'error', paymentMethod: this.paymentMethod });
      stepper.next()
    }
    this.spei_data = data
    this.spei_data["slack_response"] = post_slack
  }

  private async validateExperimentAndAcademyUser(data): Promise<boolean> {
    if (
      this.check_company &&
      this.check_company['userPlan'] &&
      this.check_company['userPlan']['userIsAcademy'] !== null &&
      (this.stateName === 'Yucatán' || this.stateName === 'Michoacán')
    ) {

      // POST SLACK PARA CREAR EL USUARIO DE ACADEMIA
      this.userService.addLogTransaction("post_slack_deal " + this.secondFormControl.email.value, null, this.hashSession)
      data['userIsAcademy'] = true;

      let post_slack: any = await this.PostSlack.postContalinkSlack(
        data
      ).toPromise()

      this.finishMichoacan(
        false,
        'Ya casi, solo un paso más',
        `Tu correo tiene acceso a Academia Contalink, para convertirlo a usuario un asesor se comunicará contigo. 
         Para cualquier duda puedes escribirnos a info@contalink.com o por Whatsapp al 811 910 7569`,
        null
      );

      return true;
    }
    return false;
  }

  private validateRfcLite(data: any): boolean {
    if (this.check_company['found_companies'] > 0 && !this.is_lite) {
      if (this.check_company['is_rfc_lite']) {
        alert("El RFC ingresado corresponde a una cuenta Lite, ingresa el correo electrónico asociado a dicha cuenta para continuar.");
        // this.stepTwoReadMode(false);
        this.secondFormGroup.get('email').setValue(null);
      } else {
        let aux_rfc = this.secondFormGroup.get('rfc_invoicing').value
        this.secondFormGroup.get('rfc_invoicing').setValue(null);
        this.secondFormGroup.get('rfc').setValue(null);
        if ((this.check_company['found_deals']['active'] || 0) > 0) {
          this.secondFormGroup.get('rfc_invoicing').setValue(null);
          this.secondFormGroup.get('rfc').setValue(null);
          data["rfc_exists"] = true
          data["active_deals"] = true
          this.slack_submitted = false
          this.userService.addLogTransaction("validation_existing_tax_id", null, this.hashSession)
          this.obAgentName = this.check_company['found_deals']['obagent_name'];
          this.obAgentEmail = this.check_company['found_deals']['obagent_email'];
          this.obAgentPhone = this.check_company['found_deals']['obagent_phone'];
          this.openRFCModal("company", aux_rfc)

          return true;
        } else {
          // Encontró un deal cancelado
          data["canceled_deals"] = true
        }
      }
    }

    return false;
  }

  private validateLineOfBusiness(): string {
    console.log('line of business', this.selectLineOfBusinessId);
    if (this.selectLineOfBusinessId === null || this.selectLineOfBusinessId === '') {
      return '\n- Giro de tu negocio';
    }
    return '';
  }

  private validatePipedriveReference(): string {
    if (this.pipedriveReference === null || this.pipedriveReference === '') {
      return '- Cómo escucho de nosotros?';
    }
    return '';
  }

  private validateSecondFormInputs(field: any): void {
    if (field == "phone") {
      this.secondFormGroup.get('phone').setValue(this.secondFormGroup.get('phone').value.replace(/\D/g, ''));

      if (this.secondFormGroup.get('phone').value.length != 10) {
        this.textToolTipPhone = "El número de tener 10 dígitos"
        this.renderer.setStyle(this.phoneElement.nativeElement, 'textDecoration', 'underline dashed #F08080');
      } else {
        this.textToolTipPhone = "El número es correcto"
        this.renderer.setStyle(this.phoneElement.nativeElement, 'textDecoration', 'none');
      }
    }

    if (field == "email") {
      this.secondFormGroup.get('username').setValue(this.secondFormGroup.get('email').value)
      if (this.secondFormGroup.get('email').invalid) {
        this.renderer.setStyle(this.emailElement.nativeElement, 'textDecoration', 'underline dashed #F08080');
      } else {
        this.renderer.setStyle(this.emailElement.nativeElement, 'textDecoration', 'none');
      }
    }

    if (field == "rfc") {
      this.secondFormGroup.get('rfc').setValue(this.secondFormGroup.get('rfc').value.toUpperCase());
      if (this.secondFormGroup.get('rfc').invalid) {
        this.renderer.setStyle(this.rfcElement.nativeElement, 'textDecoration', 'underline dashed #F08080');
      } else {
        this.renderer.setStyle(this.rfcElement.nativeElement, 'textDecoration', 'none');
      }
    }
    if (field == "postal_code") {
      this.secondFormGroup.get('postal_code').setValue(this.secondFormGroup.get('postal_code').value.replace(/\D/g, ''));
    }
  }

  private seeStepTwoDownForm() {
    // if (!this.isMobile) {
    //   window.scroll(0, 200)
    // }
  }

  private assignEmailPromotional(emailPromotional: boolean): number {
    if (emailPromotional) return 540;
    else return 541;
  }

  private async changePriceByPlansSelected() {
    if (this.selectedPlanOption === 'PREMIUM' && this.selectedPaymentOption === 'ANUAL') {
      this.plan = this.plans.find(plan => plan.id == 68);
      this.changePlanSelected();
      this.assignTotalPrice();
    } else if (this.selectedPlanOption === 'PROFESIONAL' && this.selectedPaymentOption === 'MENSUAL') {
      this.plan = this.plans.find(plan => plan.id == 24);
      this.assignTotalPrice();
      this.changePlanSelected();
    } else if (this.selectedPlanOption === 'PROFESIONAL' && this.selectedPaymentOption === 'ANUAL') {
      this.plan = this.plans.find(plan => plan.id == 23);
      this.assignTotalPrice();
      this.changePlanSelected();
    } else if (this.selectedPlanOption === 'PREMIUM' && this.selectedPaymentOption === 'MENSUAL') {
      this.plan = this.plans.find(plan => plan.id == 67);
      this.assignTotalPrice();
      this.changePlanSelected();
    }
  }

  private async assignTotalPrice(): Promise<void> {
    this.formatter = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      minimumFractionDigits: 2
    });

    // MULTIPLICAR EL TOTAL DEL PRECIO DEL PLAN POR EL NUMERO DE USUARIOS
    if (this.plan) {
      this.plan = this.userService.getCalculationTotalDiscount(this.plan, this.referred_id);
    }

    this.printTotals2();
  }

  private async printTotals2() {
    let totalLabel = document.getElementById("totals-block-one-label-subtotal");
    let totalValue = document.getElementById("totals-block-one-value-subtotal");
    console.log('totalLabel', totalLabel);
    if (this.plan && totalLabel && totalValue) {
      totalLabel.innerHTML = "Subtotal:";
      document.getElementById("totals-block-one-value-subtotal")!.innerHTML = this.formatter.format(this.plan.subtotal);
    }

    if (this.plan && this.plan.discount > 0) {
      this.printDiscountAndTotals2();
    } else {
      this.printOnlyIvaAndTotal();
    }

    this.userService.addLogTransaction("select_plan_and_users " + this.plan.total_sale, null, this.hashSession);
  }

  private printOnlyIvaAndTotal(): void {
    if (this.formatter) {
      document.getElementById("totals-block-two-label-anual-discount").innerHTML = "IVA:";
      document.getElementById("totals-block-two-value-anual-discount").innerHTML = this.formatter.format(this.plan.iva);
      document.getElementById("totals-block-three-label-vol-discount").innerHTML = `<b> Total:</b>`;
      document.getElementById("totals-block-three-value-vol-discount").innerHTML = `<b style="color: black; font-weight: 1500;">${this.formatter.format(this.plan.total_sale)}</b>`;

      document.getElementById("totals-block-four-label-iva").innerHTML = "";
      document.getElementById("totals-block-four-value-iva").innerHTML = "";
      document.getElementById("totals-block-five-label-total").innerHTML = "";
      document.getElementById("totals-block-five-value-total").innerHTML = "";
    }
  }

  private printDiscountAndTotals2(): void {
    if (this.plan.volumen_discount_percentage > 0 && (this.plan.volumen_discount_percentage > 0 || this.referred_id != null)) {
      this.printAnnualAndVolumeDiscount(this.plan.volumen_discount_percentage);
    } else if (this.plan.volumen_discount_percentage > 0 && (this.plan.welcome_discount_percentage > 0 || this.referred_id != null)) {
      this.printWelcomeAndVolumeDiscount(this.plan.volumen_discount_percentage);
    } else {
      this.printEitherDiscount(this.plan.volumen_discount_percentage);
    }
  }

  private printAnnualAndVolumeDiscount(volumenDiscountText: any): void {
    if (this.formatter) {
      if (this.plan.annual_discount_percentage > 0) {
        document.getElementById("totals-block-two-label-anual-discount").innerHTML = "Descuento Anual:";
        document.getElementById("totals-block-two-value-anual-discount").innerHTML = this.formatter.format(this.plan.annual_discount_amount);

        document.getElementById("totals-block-three-label-vol-discount").innerHTML = "Descuento por volumen " + this.plan.volumen_discount_percentage + "%";

        document.getElementById("totals-block-three-value-vol-discount").innerHTML = this.formatter.format(this.plan.volumen_discount_amount);
      } else {
        document.getElementById("totals-block-three-label-vol-discount").innerHTML = "Descuento por volumen " + this.plan.volumen_discount_percentage + "%";

        document.getElementById("totals-block-three-value-vol-discount").innerHTML = this.formatter.format(this.plan.volumen_discount_amount);

        document.getElementById("totals-block-two-label-anual-discount").innerHTML = "Descuento Bienvenida " + (this.plan.welcome_discount_percentage || this.plan.annual_discount_percentage) + "%:";
        document.getElementById("totals-block-two-value-anual-discount").innerHTML = this.formatter.format(this.plan.welcome_discount_amount || this.plan.annual_discount_amount);
      }

      document.getElementById("totals-block-four-label-iva").innerHTML = "IVA:";
      document.getElementById("totals-block-four-value-iva").innerHTML = this.formatter.format(this.plan.iva);

      document.getElementById("totals-block-five-label-total").innerHTML = `<b> Total:</b>`;
      document.getElementById("totals-block-five-value-total").innerHTML = `<b style="color: black; font-weight: 1500;"> ${this.formatter.format(this.plan.total_sale)}</b>`;
    }
  }

  private printEitherDiscount(volumenDiscountText: any) {
    if (this.formatter) {
      if (this.plan.volumen_discount_percentage > 0) {
        document.getElementById("totals-block-two-label-anual-discount").innerHTML = "Descuento por volumen " + this.plan.volumen_discount_percentage + "%";

        document.getElementById("totals-block-two-value-anual-discount").innerHTML = this.formatter.format(this.plan.volumen_discount_amount);
      } else {
        document.getElementById("totals-block-two-label-anual-discount").innerHTML = "Descuento Bienvenida " + (this.plan.welcome_discount_percentage || this.plan.annual_discount_percentage) + "%:";
        document.getElementById("totals-block-two-value-anual-discount").innerHTML = this.formatter.format(this.plan.welcome_discount_amount || this.plan.annual_discount_amount);
      }

      document.getElementById("totals-block-three-label-vol-discount").innerHTML = "IVA:";
      document.getElementById("totals-block-three-value-vol-discount").innerHTML = this.formatter.format(this.plan.iva);


      document.getElementById("totals-block-four-label-iva").innerHTML = `<b> Total:</b>`;
      document.getElementById("totals-block-four-value-iva").innerHTML = `<b style="color: black; font-weight: 1500;">${this.formatter.format(this.plan.total_sale)}</b>`;

      document.getElementById("totals-block-five-label-total").innerHTML = "";
      document.getElementById("totals-block-five-value-total").innerHTML = "";
    }
  }

  private printWelcomeAndVolumeDiscount(volumenDiscountText: any): void {
    if (this.formatter) {
      document.getElementById("totals-block-three-label-vol-discount").innerHTML = "Descuento por volumen " + this.plan.volumen_discount_percentage + "%";

      document.getElementById("totals-block-three-value-vol-discount").innerHTML = this.formatter.format(this.plan.volumen_discount_amount);

      document.getElementById("totals-block-two-label-anual-discount").innerHTML = "Descuento Bienvenida " + (this.plan.welcome_discount_percentage || this.plan.annual_discount_percentage) + "%:";
      document.getElementById("totals-block-two-value-anual-discount").innerHTML = this.formatter.format(this.plan.welcome_discount_amount || this.plan.annual_discount_amount);

      document.getElementById("totals-block-four-label-iva").innerHTML = "IVA:";
      document.getElementById("totals-block-four-value-iva").innerHTML = this.formatter.format(this.plan.iva);

      document.getElementById("totals-block-five-label-total").innerHTML = `<b> Total:</b>`;
      document.getElementById("totals-block-five-value-total").innerHTML = `<b style="color: black; font-weight: 1500;"> ${this.formatter.format(this.plan.total_sale)}</b>`;
    }
  }

  private async displayInfoPlan(): Promise<void> {
    let stringDescription: string = "";

    if (this.plan?.plan_short_name == 'PROFESIONAL') {
      this.ProfesionalList.forEach(profesional => { stringDescription += `<li>${profesional}</li>` });
    }
    if (this.plan?.plan_short_name == 'PREMIUM') {
      this.PremiumList.forEach(premium => { stringDescription += `<li>${premium}</li>` });
    }

    let listFeaturesPlanElement = document.getElementById("list-features-plan");

    if (this.listFeaturesPlanElement) {
      listFeaturesPlanElement!.innerHTML = stringDescription;
    }
  }

  private changePlanSelected(): void {
    this.changePlan.emit(this.plan);
  }
}
