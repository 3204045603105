import { Injectable } from "@angular/core";
import { GetPlansService } from '../../services/get-plans.service';
import { CheckCompanyService } from '../../services/check-company.service';
import { MixPanelService } from '../../services/mix-panel.service';
import { PostLogTransactionService } from '../../services/post-log-transaction.service';
import { GetLiteInfoService } from '../../services/get-lite-info.service';
import { GetReferredInfoService } from '../../services/get-referred-info.service';
import { IPlanInfo } from "src/app/interfaces/plan-info.interface";
import { DISCOUNT_TABLE } from "./list.data";
import { timingSafeEqual } from "crypto";
import { Console } from "console";
@Injectable({
  providedIn: 'root'
})

export class UserService {
  private discountTable: any = DISCOUNT_TABLE;
  private url_path: string = window.location.href;
  private planInfo: IPlanInfo;
  currentPrice: number = 0;

  constructor(
    private GetPlans: GetPlansService,
    private CheckCompany: CheckCompanyService,
    private mixpanelService: MixPanelService,
    private PostLogTransaction: PostLogTransactionService,
    public GetLiteInfo: GetLiteInfoService,
    public GetReferredInfo: GetReferredInfoService,
  ) { }

  public async allPlans(): Promise<any> {
    return await this.GetPlans.getContalinkPlans({}).toPromise();
  }

  public async checkRFC(rfc: string, email: string, phone: string, data: any): Promise<any> {
    return await this.CheckCompany.checkContalinkCompany(
      { rfc, email, phone },
      data
    ).toPromise();
  }

  public fillDataValues(secondFormControl: any, isQa: boolean, usersCount, selectedPlan, selectedRecurrence, totalSale, totalDiscount, selectedRegimenName,
    selectedRegimen, selectedFuente, selectedFuenteName, precio_deal, descuento_deal, utm_medium, utm_source, utm_campaign, fbclid, gclid, ttclid, utm_id, version, utm_term, utm_content, ad_set,
    first_utm, first_visit_date, lite_code, referred_id, referredEmail, half_off_discount, volumeDiscount, annualDiscount,
    volume_discount_amount, annual_discount_amount, acceptedPromotionalEmail, welcome_discount, free_trial, potencial_user_number, line_of_business_id,
    isUserAcademy): any {
    
    return {
      "name": secondFormControl.name.value,
      "email": secondFormControl.email.value.toLowerCase(),
      "userpass": secondFormControl.userpass.value,
      "phone": secondFormControl.phone.value,
      "rfc": secondFormControl.rfc.value,
      "razon_social": secondFormControl.company.value,
      "rfc_facturacion": secondFormControl.rfc_invoicing.value,
      "razon_social_facturacion": secondFormControl.company_invoicing.value,
      "numero_usuarios": usersCount.toString(),
      "plan_id": selectedPlan,
      "recurrence": selectedRecurrence,
      "total": totalSale,
      "discount": totalDiscount,
      "rfc_exists": false,
      "qa": isQa,
      "rfc_generico": false,
      "business_partner_id": null,
      "regimenName": selectedRegimenName,
      "regimen": selectedRegimen,
      "fuente": selectedFuente,
      "fuenteName": selectedFuenteName,
      "postal_code": secondFormControl.postal_code.value,
      "precio_deal": precio_deal,
      "descuento_deal": descuento_deal,
      "utm_medium": utm_medium,
      "utm_source": utm_source,
      "utm_campaign": utm_campaign,
      "fbclid": fbclid,
      "gclid": gclid,
      "ttclid": ttclid,
      "utm_id": utm_id,
      "version": version,
      "utm_term": utm_term,
      "utm_content": utm_content,
      "ad_set": ad_set,
      "first_utm": first_utm,
      "first_visit_date": first_visit_date,
      "lite_code": lite_code,
      "referred": referred_id == null ? 'false' : 'true',
      "referred_code": referred_id,
      "referred_name": referredEmail,
      "halfoff": half_off_discount,
      "new_rfc_lite": null,
      "volume_discount": volumeDiscount,
      "annual_discount": annualDiscount,
      "volume_discount_amount": volume_discount_amount,
      "annual_discount_amount": annual_discount_amount,
      "acceptedPromotionalEmail": acceptedPromotionalEmail,
      "welcome_discount": welcome_discount,
      "freetrial": free_trial,
      "potential_user_number": potencial_user_number,
      "line_of_business_id": line_of_business_id,
      "is_user_academy": isUserAcademy
    }
  }

  addMixPanelEvent(action, params): void {
    this.mixpanelService.track(
      action,
      params,
      this.url_path
    );
  }

  addMixPanelReferredEvent(action, params): void {
    this.mixpanelService.referredTrack(
      action,
      params,
      this.url_path
    );
  }

  async addLogTransaction(action, landing_record_id, hashSession): Promise<void> {
    let browser_t = new Date();
    let post_slack: any = await this.PostLogTransaction.postContalinkLogTransaction(
      {
        action_key: action,
        browsertime: browser_t.toISOString(),
        session_id: hashSession,
        record_landing_id: landing_record_id
      }
    ).toPromise()
  }


  validateAllFields(secondFormGroup: any, selectedRegimen: any, acceptedTyC: boolean, isMichoacan: boolean = false): any {
    let message: string = "";

    if (secondFormGroup.get('name').invalid) {
      message = message + "- Nombre\n"
    }
    if (secondFormGroup.get('phone').invalid) {
      message = message + "- Télefono\n"
    }
    if (secondFormGroup.get('email').invalid) {
      message = message + "- Email\n"
    }
    if (secondFormGroup.get('userpass').invalid) {
      message = message + "- Contraseña\n"
    }
    if (secondFormGroup.get('company').invalid) {
      message = message + "- Razón Social\n"
    }
    if (secondFormGroup.get('rfc').invalid) {
      message = message + "- RFC\n"
    }
    if (!isMichoacan) {
      if (secondFormGroup.get('postal_code').invalid) {
        message = message + "- Código Postal\n"
      }
      if (selectedRegimen == null) {
        message = message + "- Régimen Fiscal\n"
      }
    }

    if (!acceptedTyC) {
      message += "- Acepta los términos y condiciones\n"
    }
    if (message != "") {
      return {
        "status": false,
        "message": "Favor de validar la siguiente información obligatoria:\n" + message
      }
    } else {
      return { "status": true }
    }
  }

  makeHashSession(): string {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 30; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  async getLiteInfo(lite_code: any): Promise<any> {
    return await this.GetLiteInfo.getContalinkLiteInfo({
      lite_code: lite_code
    }).toPromise();
  }

  async getReferredInfo(referred_id): Promise<any> {
    return await this.GetReferredInfo.getContalinkReferredInfo({
      referred_id: referred_id
    }).toPromise();
  }

  getDefaultPlanName(plan: any): string {
    const planName = plan.landing_freemium_label.split(' ')[0].toLowerCase();
    return planName;
  }

  getPaymentPlanName(plan: any): string {
    const paymentName = plan.landing_freemium_label.split(' ')[1].toLowerCase();
    return paymentName;
  }

  getIvaPrice(plan: IPlanInfo): number {
    const iva = this.currentPrice * 0.16;
    return iva;
  }

  getCalculationReferrerDiscount(plan: any): number {
    return 0;
  }

  getCalculationVolumenDiscount(plan: IPlanInfo): number {
    const volumenDiscount = this.getVolumeDiscount(plan.number_of_users);
    const volumenDiscountAmount = this.currentPrice * (volumenDiscount / 100);
    
    return volumenDiscountAmount;
  }

  getCalculationWelcomeDiscount(plan: any): number {
    const welcomeDiscountPercentage = Number(plan.welcome_discount_percentage);
    const welcomeDiscountAmount = this.currentPrice * (welcomeDiscountPercentage / 100);
    
    return welcomeDiscountAmount;    
  }

  getCalculationAnualDiscount(plan: IPlanInfo): number {
    const anualDiscountPercentage = plan.annual_discount_percentage;
    const anualDiscountAmount = this.currentPrice * (anualDiscountPercentage / 100);

    return anualDiscountAmount;
  }

  getCalculationTotalDiscount(plan: IPlanInfo, referrer: boolean): IPlanInfo {
    this.planInfo = plan;
    this.planInfo.discount = 0;
    
    // MULTIPLICAR EL TOTAL DEL PRECIO DEL PLAN POR EL NUMERO DE USUARIOS
    this.planInfo.price = Number(plan.price);
    this.currentPrice = Number(plan.price) * plan.number_of_users;

    // RETORNAR DIFERENTES VALORES CUANDO EL ESTADO SEA YUCATAN
    if (plan.state_name === 'Michoacán' && !this.planInfo.is_referer) {
      this.currentPrice = 0;
      this.planInfo.subtotal = 0;
      this.planInfo.annual_discount_amount = 0;
      this.planInfo.discount = 0;
      this.planInfo.welcome_discount_percentage = 0;
      this.planInfo.volumen_discount_amount = 0;
      this.planInfo.half_off_discount = 0;
      this.planInfo.iva = 0;
      this.planInfo.total_sale = 0;
      this.planInfo.welcome_discount_amount = 0;
      this.planInfo.annual_discount_percentage = 0;
      this.planInfo.volumen_discount_percentage = 0;
      this.planInfo.discount_deal = 0;
      this.planInfo.number_of_users = 1;
      this.planInfo.potential_user_number = plan.number_of_users;

      return this.planInfo;
    }

    // DESCUENTO ANUAL SI LA RECURRENCIA ES 12
    if (plan.recurrence == 12) {
      this.planInfo.half_off_discount = 0;

      if (referrer != null) { 
        this.planInfo.annual_discount_percentage = 33.33;
      }

      this.currentPrice = this.currentPrice * 12;
      // SUBTOTAL
      this.planInfo.subtotal = this.currentPrice;
      // CALCULAR DESCUENTO ANUAL
      const annualDiscountPrice = this.getCalculationAnualDiscount(plan);
      // PRECIO CON DESCUENTO ANUAL
      this.currentPrice = this.currentPrice - annualDiscountPrice;
      // DESCUENTO ANUAL
      this.planInfo.annual_discount_amount = annualDiscountPrice;
      // DESCUENTO DEAL
      this.planInfo.discount = this.planInfo.discount + annualDiscountPrice;

      // DESCUENTO BIENVENIDA EN 0
      this.planInfo.welcome_discount_percentage = 0;
      this.planInfo.volumen_discount_amount = 0;

    } else {
      // SI NO ES ANUAL SE ASIGNA EL PRECIO DEL PLAN CON DESCUENTO DE BIENVENIDA
      this.planInfo.subtotal = this.currentPrice;
      const welcomeDiscountPrice = this.getCalculationWelcomeDiscount(plan);
      this.planInfo.welcome_discount_amount = welcomeDiscountPrice;
      this.currentPrice = this.currentPrice - welcomeDiscountPrice;

      this.planInfo.discount = this.planInfo.discount + welcomeDiscountPrice;

      if (referrer != null) { 
        this.planInfo.half_off_discount = 4;
        this.planInfo.welcome_discount_text = null;
      }
      else {
        if (plan.state_name === 'Yucatán') {
          this.planInfo.half_off_discount = 1;
        } else {
          this.planInfo.half_off_discount = 3;
        }
      }
      this.planInfo.annual_discount_percentage = 0;
      this.planInfo.annual_discount_amount = 0;
    }
    
    // DESCUENTO POR VOLUMEN DE USUARIOS
    if (plan.number_of_users > 1) {
      if (plan.state_name !== 'Yucatán') {
        const volumenDiscountPrice = this.getCalculationVolumenDiscount(plan);
        this.planInfo.volumen_discount_percentage = this.getVolumeDiscount(plan.number_of_users);
        this.planInfo.volumen_discount_amount = volumenDiscountPrice;
        this.currentPrice = this.currentPrice - volumenDiscountPrice;

        this.planInfo.discount = this.planInfo.discount + volumenDiscountPrice;

      // SE ASIGNA EL DESCUENTO POR VOLUMEN DE USUARIOS AL PLAN
        this.discountDealVolume();
      } else {
        this.planInfo.volumen_discount_percentage = 0;
        this.planInfo.volumen_discount_amount = 0;
        this.planInfo.volumen_discount_percentage = 0;
        this.planInfo.half_off_discount = 1;
      }
    } else {
      // SI NO HAY VOLUMEN DE USUARIOS SE ASIGNA 0
      this.planInfo.volumen_discount_percentage = 0;
      this.planInfo.volumen_discount_amount = 0;
      this.planInfo.volumen_discount_percentage = 0;
    }

    // SUMAR IVA A LOS DESCUENTOS
    const ivaPrice = this.getIvaPrice(plan);
    this.currentPrice = this.currentPrice + ivaPrice;
    this.planInfo.iva = ivaPrice;

    // TOTAL PRICE FINAL
    this.planInfo.total_sale = this.currentPrice;

    return this.planInfo;
  }

  private getVolumeDiscount(quantity: number): number {
    if (quantity >= 5) {
      return 40;
    }
    return this.discountTable[quantity] || 0;
  }

  private discountDealVolume(): void {
    // PRECIO POR EL DESCUENTO DE BIENVENIDA
    const descuento_deal = this.planInfo.price *
     (Number(this.planInfo.welcome_discount_percentage ||
      this.planInfo.annual_discount_percentage) / 100);

    const newPrice = this.planInfo.price - descuento_deal;
    // PRECIO POR EL DESCUENTO DE V
    const descuento_volumen = (newPrice || 0) * (this.planInfo.volumen_discount_percentage / 100);

    this.planInfo.discount_deal = descuento_deal + descuento_volumen;
  }
}