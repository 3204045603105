<notifier-container></notifier-container>
<!--<app-modal-rfc (closeModal)="closeModal()"></app-modal-rfc>
ModalRfcComponent-->
<app-modal-rfc (closeModal)="closeRFCModal()"></app-modal-rfc>
<mat-horizontal-stepper #stepper linear id="hzt_stepper">
  <mat-step [stepControl]="firstFormGroup" [completed]="true">
    <ng-template matStepLabel>Usuarios y Planes</ng-template>
    <form [formGroup]="firstFormGroup" (keydown.enter)="false">
      <!-- P  R   I   M   E   R     P   A   S   O -->
      <app-step-one-quoter *ngIf="planInformation && plansInterface && plansInterface.length" [plan]="planInformation"
        [plans]="plansInterface" [lite_code]="lite_code" [is_lite]="is_lite" [liteInfo]="liteInfo"
        (executeGoToStepTwo)="goStepTwo();"
        [referred_id]="referred_id" 
        [referredName]="referredName" [referredEmail]="referredEmail"
        [stateName]="stateName"
        (changePlan)="assignPlanFromStepOne($event);">
      </app-step-one-quoter>
    </form>
  </mat-step>

  <!-- P  R   I   M   E   R     P   A   S   O     F   I   N   -->
  <!-- SEGUNDO PASO -->
  <mat-step [stepControl]="secondFormGroup" completed="stepTwoCompleted">
    <form [formGroup]="secondFormGroup" (keydown.enter)="false">
      <ng-template matStepLabel>Información de Registro</ng-template>
      <div class="users-form-bckgd" style="margin-top: 1em;">
        <div class="row">
          <div class="col-lg-offset-2 col-lg-8 container-fluid">
            <div class="row" style="height: 39.5em;" id="second-step-form-div">
              <div id="image-form-mobil" class="col-lg-5 col-xs-12 col-md-5 border-cards overlay"
                style="
                   border-top-right-radius: 0px;
                   border-bottom-right-radius: 0px;
                   display: flex;
                   justify-content: center;
                   align-items: center;
                   height: 100%;
                   font-weight: 600;
                   font-size: 1.8em;
                   font-family: Lexend;
                   background: url('https://contalink-reports.s3.amazonaws.com/PROD/LOGOS/CONTALINK/background_form.png');">
                <div class="container">
                  <div class="col-lg-12">
                    <label style="display: flex; justify-content: center; color: white;">
                      Para el contador
                    </label>
                  </div>
                  <div class="col-lg-12">
                    <label style="display: flex; justify-content: center; color: white;">
                      que busca el cambio,
                    </label>
                  </div>
                  <div class="col-lg-12">
                    <label style="display: flex; justify-content: center; color: #12EFF9;;">
                      bienvenido a Contalink.
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-lg-7 col-xs-12 col-md-7 border-cards container-fluid" style="background-color: #fdfbfb; height: 39.5em;
                 font-family: Lexend;
                 border-top-left-radius: 0px;
                 border-bottom-left-radius: 0px;">
                <div class="container-fluid h-100">
                  <div class="row h-100">
                    <br>
                    <div class="col-lg-offset-1 col-lg-10 col-md-10 col-md-offset-1"
                      style="height: 42em; bottom: 1.5em;">
                      <h2 style="color: #0275d8; font-weight: 700; font-size: 20px;" class="lexend">Regístrate</h2>

                      <div class="container-fluid row" style="padding-left: 0px; padding-right: 0px;">
                        <div class="form-group col-lg-6 col-md-6 col-xs-6">
                          <label for="" class="input-label">Nombre completo</label>
                          <input (input)="onCapture('name')" type="text" class="form-control input-form-height"
                            formControlName="name" [ngClass]="{'': is_valid }" id="name">
                        </div>
                        <div class="form-group col-lg-6 col-md-6 col-xs-6">
                          <label for="" class="input-label">Correo (usuario)</label>
                          <input (input)="onCapture('email')" type="email" class="form-control input-form-height"
                            formControlName="email" [ngClass]="{'': is_valid }" id="email" #email>
                        </div>
                      </div>
                      <div class="container-fluid row" style="padding-left: 0px; padding-right: 0px;">
                        <div class="form-group col-lg-6 col-md-6 col-xs-6">
                          <label for="" class="input-label">Celular (Whatsapp)</label>
                          <input (input)="onCapture('phone')" type="text" class="form-control input-form-height"
                            formControlName="phone" [ngClass]="{'': is_valid }" id="phone" #phone>
                          <div class="row container-fluid">
                            <label style="font-weight: 600; font-size: 11px; color: #6E6E6E;">
                              Importante: validaremos aqui tu cuenta
                            </label>
                          </div>
                        </div>
                        <div class="form-group col-lg-5 col-md-5 col-xs-5" style="display: none;">
                          <label for="" class="input-label">Genera tu contraseña</label>
                          <input (input)="onCapture('userpass')" type="password"
                            [type]="fieldTextType ? 'text' : 'password'" class="form-control input-form-height"
                            id="userpass" formControlName="userpass" [ngClass]="{'': is_valid }">
                        </div>
                        <div class="form-group col-lg-1 col-md-1 col-xs-1" style="display: none;">
                          <mat-icon style="position: relative; top: 1.1em;" class="password-toggle"
                            (click)="toggleFieldTextType()">{{fieldTextType ? 'visibility' :
                            'visibility_off'}}</mat-icon>
                        </div>
                      </div>
                      <h4 style="color: #0275d8; font-weight: 700; font-size: 14px; margin-top: 0;" class="lexend">Datos
                        de facturación</h4>
                      <div class="container-fluid row" style="padding-left: 0px; padding-right: 0px;">
                        <div class="form-group col-lg-6 col-md-6 col-xs-6">
                          <label for="" class="input-label">Rázon Social</label>
                          <input (input)="onCapture('company')" type="text" class="form-control input-form-height"
                            formControlName="company" [ngClass]="{'': is_valid }" id="company">
                        </div>
                        <div class="form-group col-lg-6 col-md-6 col-xs-6">
                          <label for="" class="input-label">RFC</label>
                          <input (input)="onCapture('rfc')" type="text" class="form-control input-form-height"
                            formControlName="rfc" [ngClass]="{'': is_valid }" id="rfc" #rfc>
                        </div>
                      </div>
                      <div class="container-fluid row" style="padding-left: 0px; padding-right: 0px;">
                        <div class="form-group col-lg-6 col-md-6 col-xs-6">
                          <label for="" class="input-label">Código Postal</label>
                          <input (input)="onCapture('postal_code')" type="text" class="form-control input-form-height"
                            formControlName="postal_code" [ngClass]="{'': is_valid }" id="postal_code" #postal_code>
                        </div>
                        <div class="form-group col-lg-6 col-md-6 col-xs-6">
                          <label for="" class="input-label">Régimen Fiscal</label>
                          <select name="regimen_fiscal" id="regimen_fiscal" class="form-control input-form-height"
                            (change)="onChangeRegimen()">
                            <optgroup class="label-users-field">
                              <option style="display:none;"></option>
                              <option *ngFor="let regimen of regimenList" [value]="regimen.id"
                                [attr.data-value]="regimen.value" [attr.data-id]="regimen.id">
                                {{regimen.value}}
                              </option>
                            </optgroup>
                          </select>
                        </div>
                      </div>
                      <div class="row" style="margin-top: -20px; margin-bottom: -15px;">
                        <div class="col-lg-1" style="padding-right: 0%;">
                          <input type="checkbox" name="" style="width:15px;" id="accept_tyc"
                            (change)="acceptedTyC = !acceptedTyC">
                        </div>
                        <div class="col-lg-11" style="padding-left: 0%;">
                          <label class="acept-terms-and-conditions">
                            Acepto los
                            <a href="assets/TERMINOS Y CONDICIONES CONTALINK 08_23.pdf" target="_blank"
                              rel="noopener noreferrer" (change)="acceptedTyC = !acceptedTyC">
                              términos y condiciones
                            </a>
                          </label>
                        </div>
                      </div>
                      <div class="row" style="margin-top: 0px; margin-bottom: -15px;">
                        <div class="col-lg-1" style="padding-right: 0%;">
                          <input type="checkbox" name="" style="width:15px;" id="acceptedEmailPromotional"
                            (click)="changeAcceptedPromotionalEmail()" checked>
                        </div>
                        <div class="col-lg-11" style="padding-left: 0%;">
                          <label class="acept-terms-and-conditions">
                            Acepto recibir correos electrónicos de comunicados y promociones.
                          </label>
                        </div>
                      </div>
                      <br>
                      <div class="container-fluid row" style="margin-top: -10px;">
                        <div style="width:100%;" id="stepperNextStepTwo">
                          <!--<button mat-button matStepperNext (click)="sendData();"-->
                          <button mat-button (click)="sendData(stepper);" id="" (mousedown)="DownBtn('two')"
                            [disabled]="!acceptedTyC" style="width:100%;
                            color: white;
                            font-size: 14px;
                            border-radius: 20px;
                            height: 38px;
                            font-family: Lexend;"
                            [ngClass]="{ 'active-button': acceptedTyC, 'inactive-button': !acceptedTyC }">
                            Crear cuenta
                          </button>
                          <!-- background-color: #050029; -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br><br>
      </div>
    </form>
    <!-- S  E   G   U   N   D   O     P   A   S   O     F   I   N   -->
  </mat-step>
  <!-- FIN SEGUNDO PASO -->

  <mat-step completed="stepPMethodCompleted">
    <ng-template matStepLabel>Método de Pago</ng-template>
    <div class="users-form-bckgd" style="margin-top: 2em;">
      <div class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-6 col-sm-offset-3">
        <p class="users-bg-img-text"></p>
        <div class="users-form form-inline" role="form">
          <mat-card>
            <mat-card-title>Método de Pago</mat-card-title>
            <!-- Esto viajará al paso del m pago -->
            <mat-card-subtitle>
              Seleccione su método de pago.
            </mat-card-subtitle><br>
            <!-- LISTA DE MÉTODOS DE PAGO -->
            <mat-card *ngIf="!isMobile" class="mat-card-paymethod" id="card_payment_tile"
              (click)="change_payment_method('card')">
              <mat-grid-list cols="7" rowHeight="2:0.5">
                <mat-grid-tile colspan="2" rowspan="2">
                  <!--<img src="https://fit78online.com/images/tarjetasfit.png" width="80%">-->
                  <img src="https://contalink-reports.s3.amazonaws.com/PROD/LOGOS/CONTALINK/visa.png" width="80%">
                </mat-grid-tile>
                <mat-grid-tile colspan="4">
                  <p style="text-align:left;width:100%;">Tarjeta de Crédito o débito</p>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="2">
                  <input class="payment_method_check" type="checkbox" name="" style="width:15px;"
                    id="card_payment_method">
                </mat-grid-tile>
                <mat-grid-tile colspan="4">
                  <p style="text-align:left;width:100%;">Acceso inmediatamente después de realizar su pago.</p>
                </mat-grid-tile>
              </mat-grid-list>
            </mat-card><br>
            <mat-card *ngIf="!isMobile" class="mat-card-paymethod" id="spei_oxxo_payment_title"
              (click)="change_payment_method('spei_oxxo')">
              <mat-grid-list cols="7" rowHeight="2:0.5">
                <mat-grid-tile colspan="2" rowspan="2">
                  <img src="https://contalink-reports.s3.amazonaws.com/PROD/LOGOS/CONTALINK/spei_oxxo.png" width="88%">
                </mat-grid-tile>
                <mat-grid-tile colspan="4">
                  <p style="text-align:left;width:100%;">Transferencia Bancaria (CLABE)</p>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="2">
                  <input class="payment_method_check" type="checkbox" name="" style="width:15px;"
                    id="spei_oxxo_payment_method">
                </mat-grid-tile>
                <mat-grid-tile colspan="4">
                  <p style="text-align:left;width:100%;font-size:12px;">Enviaremos a su correo un documento con
                    información para pago.
                  </p>
                </mat-grid-tile>
              </mat-grid-list>
            </mat-card><br>
            <br>
            <!-- LISTA DE MÉTODOS DE PAGO MóVIL-->
            <mat-card *ngIf="isMobile" class="mat-card-paymethod" id="card_payment_tile"
              (click)="change_payment_method('card')">
              <mat-grid-list cols="7" rowHeight="2:1.5">
                <mat-grid-tile colspan="2" rowspan="2">
                  <img src="https://contalink-reports.s3.amazonaws.com/PROD/LOGOS/CONTALINK/visa.png" width="80%">
                </mat-grid-tile>
                <mat-grid-tile colspan="4">
                  <p style="text-align:left;width:100%;"><b>Tarjeta de Crédito</b></p>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="2">
                  <input class="payment_method_check" type="checkbox" name="" style="width:15px;"
                    id="card_payment_method">
                </mat-grid-tile>
                <mat-grid-tile colspan="4">
                  <p style="text-align:left;width:100%;">Acceso inmediato.</p>
                </mat-grid-tile>
              </mat-grid-list>
            </mat-card><br>
            <mat-card *ngIf="isMobile" class="mat-card-paymethod" id="spei_oxxo_payment_title"
              (click)="change_payment_method('spei_oxxo')">
              <mat-grid-list cols="7" rowHeight="2:1.5">
                <mat-grid-tile colspan="2" rowspan="2">
                  <img src="https://contalink-reports.s3.amazonaws.com/PROD/LOGOS/CONTALINK/spei_oxxo.png" width="80%">
                </mat-grid-tile>
                <mat-grid-tile colspan="4">
                  <p style="text-align:left;width:100%;">Transferencia Bancaria (CLABE)</p>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="2">
                  <input class="payment_method_check" type="checkbox" name="" style="width:15px;"
                    id="spei_oxxo_payment_method">
                </mat-grid-tile>
                <mat-grid-tile colspan="4">
                  <p style="text-align:left;width:100%;">Enviaremos a su correo un documento con información para pago.
                  </p>
                </mat-grid-tile>
              </mat-grid-list>
            </mat-card><br>
            <!-- BOTÓN SIGUIENTE -->
            <mat-grid-list *ngIf="!isMobile" cols="4" rowHeight="2:0.5">
              <mat-grid-tile colspan="1">
                <!--<button style="width:100%;background-color:#D7E2EA;" 
                  mat-button matStepperPrevious>Atrás</button>-->
              </mat-grid-tile>
              <mat-grid-tile colspan="2">
              </mat-grid-tile>
              <mat-grid-tile colspan="1">
                <div style="width:100%;" id="stepperNextStepPayment" [hidden]="paymentMethod == null">
                  <button mat-button (click)="goPayment(stepper);" id="btn_next_three" (mousedown)="DownBtn('three')"
                    (mouseup)="UpBtn('three')" style="width:100%;background-color:#D7E2EA;">Siguiente</button>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
            <!-- BOTÓN SIGUIENTE MÓVIL-->
            <mat-grid-list *ngIf="isMobile" cols="4" rowHeight="2:1">
              <mat-grid-tile colspan="4">
                <div style="width:100%;" id="stepperNextStepPayment" [hidden]="paymentMethod == null">
                  <button mat-button (click)="goPayment(stepper);" id="btn_next_three" (mousedown)="DownBtn('three')"
                    (mouseup)="UpBtn('three')"
                    style="width:100%;background-color:#D7E2EA;height:3em;">Siguiente</button>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </mat-card>
        </div>
      </div>
    </div>
  </mat-step>
  <!-- T  E   R   C   E   R     P   A   S   O     F   I   N   --> -

  <mat-step completed="stepThreeCompleted">
    <ng-template matStepLabel>Información de Pago</ng-template>
    <div class="users-form-bckgd" style="margin-top: 2em; margin-bottom: 12em;">
      <div class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-6 col-sm-offset-3">
        <p class="users-bg-img-text"></p>
        <div class="users-form form-inline" role="form">
          <mat-card>
            <h2><b>Su Pago</b></h2>
            <p>Total: {{ planInformation?.total_sale || 0 | number:'1.2-2' }} MXN</p>
            <form id="payment-form">
              <form id="payment-form">
                <div id="payment-element"></div>
              </form>
            </form>

            <mat-grid-list cols="4" rowHeight="2:1">
              <mat-grid-tile colspan="4">
                <div style="width:25%;">
                  <button mat-button style="width:100%;background-color:#D7E2EA;height:3em;"
                    (click)="goStepThree(stepper);">
                    Cambiar Método de Pago
                  </button>
                </div>
                <div style="width:55%;">
                </div>
                <div style="width:25%;" id="stepperNextStepPayment">
                  <!-- <button mat-button (click)="goToStep(4, stepper);"  -->
                  <button mat-button (click)="goToStep(4, stepper);" id="btn_next_three" (mouseup)="UpBtn('three')"
                    style="width:100%;background-color:#D7E2EA;height:3em;" [disabled]="!cardInputs">Confirmar
                    tarjeta</button>
                </div>
              </mat-grid-tile>

            </mat-grid-list>
            <script src="https://js.stripe.com/v3/"></script>
          </mat-card>
        </div>
      </div>
    </div>
  </mat-step>

  <mat-step completed="stepFourCompleted">
    <ng-template matStepLabel>Pago</ng-template>
    <div class="users-form-bckgd" style="margin-top: 2em; margin-top: 7em; margin-bottom: 20em;">
      <div class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-6 col-sm-offset-3">
        <div class="users-form form-inline text-center" role="form">
          <mat-card>
            <mat-card-title>Información de Pago</mat-card-title>
            <mat-card-subtitle *ngIf="(this.planInformation && this.planInformation.total_sale || '')">
              Su Pago por: {{ this.planInformation.total_sale || 0 | number:'1.2-2' }} MXN
            </mat-card-subtitle>
            <mat-card-subtitle *ngIf="(this.planInformation && this.planInformation.plan_short_name || '')">
              Plan: {{this.planInformation.plan_short_name || ''}}
            </mat-card-subtitle>
            <div id="error-message"></div>

            <mat-grid-list cols="4" rowHeight="2:1">
              <mat-grid-tile colspan="4">
                <div style="width:25%;">
                  <button mat-button style="width:100%;background-color:#D7E2EA;height:3em; margin-top: 1.5em;"
                    (click)="goToStep(3, stepper);">
                    Cambiar tarjeta
                  </button>
                </div>
                <div style="width:55%;">
                </div>
                <div style="width:25%;" id="stepperNextStepPayment">
                  <button (click)="sendPay(stepper)"
                    style="width:100%;background-color:#70B2E5;padding-top:0.6em;padding-bottom:0.6em;font-weight:bold;margin-top:2em;color:white;border:1px solid #70B2E5;"
                    id="submit" type="button">Pagar
                  </button>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </mat-card>
        </div>
      </div>
    </div>
  </mat-step>


  <mat-step completed="stepFiveCompleted">
    <ng-template matStepLabel>¡Listo!</ng-template>
    <div class="users-form-bckgd" style="margin-bottom: 12em; margin-top: 2em;">
      <div class="main-container min-h-screen flex flex-col items-center justify-center relative pt-50 pb-8">
        <h1 class="title text-contalink-primary text-center text-4xl md:text-6xl lg:text-7xl font-bold animate-fade">
          {{ pageTitle ? pageTitle : "¡Gracias por contactarnos!" }}
        </h1>
        <h2 *ngIf="pageSubtitle"
          class="title text-contalink-secondary text-center text-3xl md:text-5xl font-normal animate-fade">
          {{ pageSubtitle }}</h2>

        <div *ngIf="showExitoso" class="animate-fade">
          <p class="normal-text text-contalink-black text-xl text-center">
            Te invitamos a ver nuestro contenido de
            <a href="https://tutoriales.contalink.com/es/collections/6941920-primeros-pasos" target="_blank"
              rel="noopener noreferrer" class="link-hover font-medium">Introducción y primeros pasos</a>, con ello
            podrás conocer como funciona Contalink.
          </p>
          <p class="normal-text text-contalink-black text-xl text-center">
            Para dudas y soporte inicial puedes contactar a:
          </p>
        </div>

        <div *ngIf="showError" class="animate-fade">
          <p class="normal-text text-contalink-black text-xl">
            Tu pago ha sido procesado pero hemos tenido un problema para activar tu
            cuenta. Por favor comunícate con nuestro equipo a través del correo
            <a href="mailto:info@contalink.com" class="link-hover font-semibold">info@contalink.com</a>
            o por WhatsApp al
            <a href="https://api.whatsapp.com/send/?phone=528119107569&text&type=phone_number&app_absent=0"
              target="_blank" rel="noopener noreferrer" class="link-hover font-semibold">81 19 10 75 69</a>
            <a href="https://api.whatsapp.com/send/?phone=528119107569&text&type=phone_number&app_absent=0"
              target="_blank" rel="noopener noreferrer" class="link-hover font-semibold">81 19 10 75 69</a>
          </p>
        </div>

        <div *ngIf="showPendiente" class="animate-fade mb-16 text-center">
          <p class="normal-text text-contalink-black text-xl">
            En menos de 24 horas recibirás en tu correo la información para pagar
            mediante transferencia bancaria.
          </p>
          <p class="normal-text text-contalink-black text-xl">
            Una vez efectuado el pago, se realizará la activación de tu cuenta. Si tienes cualquier duda por favor
            contactáctanos en:
          </p>
          <p class="noraml-text text-contalink-black text-xl">
            <a href="mailto:info@contalink.com" class="link-hover font-semibold">info@contalink.com</a>
            o por WhatsApp al
            <a href="https://api.whatsapp.com/send/?phone=528119107569&text&type=phone_number&app_absent=0"
              class="link-hover font-semibold">811 910 7569</a>
          </p>
        </div>

        <div *ngIf="showLite" class="animate-fade mb-16">
          <div class="flex items-center justify-center">
            <a href="https://app.contalink.com" target="_blank" rel="noopener noreferrer"
              class="btn btn-success py-4 px-12 text-white">Iniciar sesión</a>
          </div>
        </div>

        <div style="width:100%;text-align:center; margin-top: 5em;">
          <button mat-button style="width:50%;background-color:#D7E2EA;" (click)="goStepThree(stepper);">
            Cambiar Método de Pago
          </button>
        </div>
      </div>
    </div>
  </mat-step>
</mat-horizontal-stepper>
<div class="row mt-10">
  <div class="col-lg-12 col-md-12 col-sm-12 text-center">
    <!--<a routerLink="/aviso-de-privacidad">Aviso de privacidad</a>-->
  </div>
</div>