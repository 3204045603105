<div class="row">
    <div class="container princpal-w-85 mt-1 position-relative">
        <!-- AQUI VA EL TESTIMONIO, HAY QUE MODIFICARLO PARA QUE QUEPA AQUI, cuando sea para telefono quitarlo -->
        <div class="testimonial-image-container">
            <img class="testimonial-image" src="assets/testimonio.png" alt="Contador Publico">
            <div class="testimonial-label">
                "Ahorramos hasta un 80% del tiempo" <br>
                <span>-C.P. Aldo Fabrizzio</span>
            </div>
        </div>
        <form action="">
            <section>
                <div class="row container-fluid">
                    <div class="users-form-bckgd">
                        <!-- PROCESSING WHEN STATE IS MICHOACAN -->
                        <div *ngIf="processingPayment" class="loader-overlay">
                            <div class="loader">
                                <h1>Falta poco...</h1>
                                <div class="spinner text-center"></div>
                            </div>
                        </div>

                        <div class="row justify-content-center d-flex">
                            <div class="col-lg-8">
                                <!-- CARD DE DESCUENTO PARA REFERIDOS -->
                                <mat-card-subtitle class="" *ngIf="referred_id">
                                    <div class="container-fluid d-flex justify-content-center">
                                        <b style="font-size: 17px; color:#2761AD; ">
                                            ¡Genial, {{this.referredName}} te esta invitando a probar Contalink!
                                        </b>
                                    </div>
                                    <br>
                                    <div class="container-fluid d-flex justify-content-center align-items-center"
                                        style="word-wrap: break-word; text-align: center; color: black;">
                                        <span class="" style="font-size: 12px;">Y como beneficio
                                            obtienes <b>50%
                                                de descuento</b> en tus
                                            4
                                            primeras
                                            mensualidades si contratas
                                            el plan mensual, o si prefieres el plan anual, recibirás <b>4 meses de
                                                regalo</b>
                                            al contratar tu plan.
                                        </span>
                                    </div>
                                </mat-card-subtitle>

                                <!-- CARD DE DESCUENTOS -->
                                <mat-card-subtitle *ngIf="!referred_id">
                                    <div class="justify-content-center d-flex container-fluid">
                                        <div class="new-header-text container">
                                            <div class="container">
                                                <div class="discount-text-circle col-lg-3">
                                                    <div class="">
                                                        <p class="text-discount-black">
                                                            {{
                                                            (this.stateName === 'Michoacán' || this.stateName ===
                                                            'Yucatán')
                                                            ?
                                                            '¡PROMOCIÓN!' : '¡DESCUENTO!'
                                                            }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="col-lg-9 col-xs-12 col-xs-12 col-sm-12 text-header-div d-flex justify-content-center align-items-center">
                                                    <p class="discount-gold-text">
                                                        {{plan?.header_discount_text || ''}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </mat-card-subtitle>
                            </div>
                        </div>
                        <div class="row container-fluid">
                            <div class="col-lg-1 deleted-movil"></div>
                            <div class="col-lg-10 col-xs-12 col-sm-12">
                                <div class="col-lg-6 ">
                                    <div class="container-fluid">
                                        <div class="plan-features-background-color">
                                            <div class="row logo-plan-info plan-features-background-color">
                                                <img src="https://contalink-reports.s3.amazonaws.com/PROD/LOGOS/CONTALINK/logo-white.png"
                                                    class="img-fluid" style="width: 20%; margin: 1em auto;" />
                                            </div>
                                            <div class="row plan-text-feature plan-features-background-color" [ngClass]="{
                                                'premium-text-color': plan?.plan_short_name === 'PREMIUM',
                                                'profesional-text-color': plan?.plan_short_name === 'PROFESIONAL'
                                                }">
                                                <span class="mb-05">
                                                    {{ (this.plan && this.plan?.plan_short_name | titlecase)}}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="w-100 background-color-card row border-cards border-cards-bottom">
                                            <ul class="mt-10 list-features-text" #list_features_plan
                                                id="list-features-plan"></ul>
                                        </div>
                                    </div>
                                    <div class="container-fluid col-lg-12 discount-text-promo mt-1">
                                        <div *ngIf="referred_id">
                                            {{plan && plan.discount_referres_text || ''}}
                                        </div>
                                        <br>
                                        <div *ngIf="((plan && plan.welcome_discount_text) && !referred_id)">
                                            {{plan && plan.welcome_discount_text || ''}}
                                        </div>
                                        <br>
                                        <div *ngIf="(plan && plan.volumen_discount_text && plan.number_of_users > 1)">
                                            {{plan && plan.volumen_discount_text || ''}}
                                        </div>
                                    </div>
                                    <br>
                                </div>

                                <!-- CUANDO NO ES MICHOACAN -->
                                <div class="col-lg-6 col-xl-6" *ngIf="stateName !== 'Michoacán'">
                                    <div class="row border-cards card-border-radius">
                                        <div class="row choice-plan-text ml-2">
                                            Elige tu plan:
                                        </div>
                                        <br>
                                        <div class="container-fluid row ml-1">
                                            <div class="col-lg-6 col-xs-6 col-sm-6">
                                                <input type="radio" name="plan-button"
                                                    [class.selected]="selectedPlanOption === 'PROFESIONAL'"
                                                    (click)="selectOption('PROFESIONAL')">
                                                &nbsp;
                                                <label for="profesion-button" class="input-text-label">
                                                    Profesional
                                                </label>
                                            </div>
                                            <div class="col-lg-6 col-xs-6 col-sm-6">
                                                <input type="radio" name="plan-button"
                                                    [class.selected]="selectedPlanOption === 'PREMIUM'"
                                                    (click)="selectOption('PREMIUM')" checked>
                                                &nbsp;
                                                <label for="premium-button" class="input-text-label">
                                                    Premium
                                                </label>
                                            </div>
                                        </div>

                                        <div class="row choice-plan-text ml-2">
                                            Plan de pago:
                                        </div>
                                        <br>
                                        <div class="container-fluid row ml-1">
                                            <div class="col-lg-6 col-xs-6 col-sm-6">
                                                <input type="radio" name="payment-button" id="paymentPlanMensual"
                                                    [class.selected]="this.plan?.recurrence == 'MENSUAL'"
                                                    (click)="selectPaymentOption('MENSUAL')" checked>
                                                &nbsp;
                                                <label for="plan-button" class="input-text-label">
                                                    Mensual
                                                </label>
                                            </div>
                                            <div class="col-lg-6 col-xs-6 col-sm-6">
                                                <input type="radio" name="payment-button" id="paymentPlanAnual"
                                                    [class.selected]="this.plan?.recurrence == 'ANUAL'"
                                                    (click)="selectPaymentOption('ANUAL')">
                                                &nbsp;
                                                <label for="plan-button" class="input-text-label">
                                                    Anual
                                                </label>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="container-fluid ">
                                            <div class="col-lg-10">
                                                <div class="col-6 user-number-container">
                                                    <div class="row d-flex">
                                                        <div
                                                            class="col-lg-5 col-xs-6 col-sm-6 user-number-text justify-content-center align-items-center d-flex">
                                                            Usuarios
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <button mat-fab style="background-color: #fbc3c3;"
                                                                class="button-width" (click)="userCount('less')"
                                                                type="button">
                                                                <mat-icon>remove</mat-icon>
                                                            </button>
                                                        </div>
                                                        <div class="col-lg-1-users">
                                                            <h4>{{plan && plan.number_of_users || ''}}</h4>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <button mat-fab class="button-width"
                                                                style="background-color: rgba(0, 128, 0, 0.7);"
                                                                (click)="userCount('plus')" type="button">
                                                                <mat-icon>add</mat-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row border-cards card-border-radius background-color-card-discounts">
                                        <br>
                                        <div class="container-fluid">
                                            <div class="col-lg-8 col-xs-8 col-sm-8">
                                                <label for="" id="totals-block-one-label-subtotal"
                                                    #totals_block_one_label_subtotal2
                                                    class="label-users-field-totals-2 total-text"></label>
                                            </div>
                                            <div class="col-lg-4 col-xs-4 col-sm-4">
                                                <label for="" id="totals-block-one-value-subtotal"
                                                    #totals_block_one_value_subtotal2
                                                    class="label-users-field-totals-2 total-text"
                                                    style="right: 0em;"></label>
                                            </div>
                                        </div>

                                        <div class="container-fluid">
                                            <div class="col-lg-8 col-xs-8 col-sm-8">
                                                <label for="" id="totals-block-two-label-anual-discount"
                                                    class="label-users-field-totals-2 total-text"
                                                    style="right: 0em;"></label>
                                            </div>
                                            <div class="col-lg-4 col-xs-4 col-sm-4">
                                                <label for="" id="totals-block-two-value-anual-discount"
                                                    class="label-users-field-totals-2 total-text"
                                                    style="right: 0em;"></label>
                                            </div>
                                        </div>

                                        <div class="container-fluid">
                                            <div class="col-lg-8 col-xs-8 col-sm-8">
                                                <label for="" id="totals-block-three-label-vol-discount"
                                                    #totals_block_three_label_vol_discount
                                                    class="label-users-field-totals-2 total-text"
                                                    style="right: 0em;"></label>
                                            </div>
                                            <div class="col-lg-4 col-xs-4 col-sm-4">
                                                <label for="" id="totals-block-three-value-vol-discount"
                                                    #totals_block_three_value_vol_discount
                                                    class="label-users-field-totals-2 total-text"
                                                    style="right: 0em;"></label>
                                            </div>
                                        </div>

                                        <div class="container-fluid">
                                            <div class="col-lg-8 col-xs-8 col-sm-8">
                                                <label for="" id="totals-block-four-label-iva"
                                                    class="label-users-field-totals-2 total-text"
                                                    style="right: 0em;"></label>
                                            </div>
                                            <div class="col-lg-4 col-xs-4 col-sm-4">
                                                <label for="" id="totals-block-four-value-iva"
                                                    class="label-users-field-totals-2 total-text"
                                                    style="right: 0em;"></label>
                                            </div>
                                        </div>

                                        <div class="container-fluid" style="margin-bottom: .5em;">
                                            <div class="col-lg-8 col-xs-8 col-sm-8">
                                                <label for="" id="totals-block-five-label-total"
                                                    class="label-users-field-totals-2 total-text"
                                                    style="right: 0em;"></label>
                                            </div>
                                            <div class="col-lg-4 col-xs-4 col-sm-4"><label
                                                    id="totals-block-five-value-total"
                                                    class="label-users-field-totals-2 total-text"
                                                    style="right: 0em;"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <!-- BOTON SIGUIENTE -->
                                    <div class="col-lg-12" style="width: 100%; height: auto; left: 0em;">
                                        <div>
                                            <button mat-button type="button" (click)="goStepTwoChild();" style="background-color:#0275d8;
                                                    font-size: .9em;
                                                    color: white; 
                                                    border-radius: 15px / 50%;
                                                    width: 100%;">Siguiente</button>
                                        </div>
                                    </div>
                                </div>
                                
                                <!-- CUANDO ES MICHOACAN -->
                                <div class="col-lg-6 col-xl-6" *ngIf="stateName === 'Michoacán'">
                                    <div class="col-lg-12 col-xl-6 border-cards"
                                        style=" height: auto; left: 0em; margin-bottom: 1em;">
                                        <div class="users-form-bckgd" style="margin-top: 1em;">
                                            <div class="container" style="width: 95%;">
                                                <h2 style="color: #0275d8; font-weight: 700; font-size: 20px;"
                                                    class="lexend">
                                                    Regístrate
                                                </h2>
                                                <form [formGroup]="secondFormGroup" (keydown.enter)="false">
                                                    <div class="container-fluid row"
                                                        style="padding-left: 0px; padding-right: 0px;">
                                                        <div class="form-group col-lg-6 col-md-6 col-xs-6">
                                                            <label for="" class="input-label">Nombre completo</label>
                                                            <input (input)="onCapture('name')" type="text"
                                                                class="form-control input-form-height"
                                                                formControlName="name" [ngClass]="{'': is_valid }"
                                                                id="name">
                                                        </div>
                                                        <div class="form-group col-lg-6 col-md-6 col-xs-6">
                                                            <label for="" class="input-label">Correo (usuario)</label>
                                                            <input (input)="onCapture('email')" type="email"
                                                                class="form-control input-form-height"
                                                                formControlName="email" [ngClass]="{'': is_valid }"
                                                                id="email" #email>
                                                        </div>
                                                    </div>
                                                    <div class="container-fluid row"
                                                        style="padding-left: 0px; padding-right: 0px;">
                                                        <div class="form-group col-lg-6 col-md-6 col-xs-6">
                                                            <label for="" class="input-label">Celular</label>
                                                            <input (input)="onCapture('phone')" type="text"
                                                                class="form-control input-form-height"
                                                                formControlName="phone" [ngClass]="{'': is_valid }"
                                                                id="phone" #phone>
                                                            <div class="row container-fluid">
                                                                <label
                                                                    style="font-weight: 600; font-size: 11px; color: #6E6E6E;">
                                                                    Whatsapp para soporte y capacitación gratuitos
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-lg-5 col-md-5 col-xs-5"
                                                            style="display: none;">
                                                            <label for="" class="input-label">Genera tu
                                                                contraseña</label>
                                                            <input (input)="onCapture('userpass')" type="password"
                                                                [type]="fieldTextType ? 'text' : 'password'"
                                                                class="form-control input-form-height" id="userpass"
                                                                formControlName="userpass" [ngClass]="{'': is_valid }">
                                                        </div>
                                                        <div class="form-group col-lg-1 col-md-1 col-xs-1"
                                                            style="display: none;">
                                                            <mat-icon style="position: relative; top: 1.1em;"
                                                                class="password-toggle"
                                                                (click)="toggleFieldTextType()">{{fieldTextType ?
                                                                'visibility' :
                                                                'visibility_off'}}</mat-icon>
                                                        </div>
                                                    </div>
                                                    <h4 style="color: #0275d8; font-weight: 700; font-size: 14px; margin-top: 0;"
                                                        class="lexend">
                                                        Datos primer empresa a operar en contalink.
                                                    </h4>
                                                    <div class="container-fluid row"
                                                        style="padding-left: 0px; padding-right: 0px;">
                                                        <div class="form-group col-lg-6 col-md-6 col-xs-6">
                                                            <label for="" class="input-label">Rázon Social</label>
                                                            <input (input)="onCapture('company')" type="text"
                                                                class="form-control input-form-height"
                                                                formControlName="company" [ngClass]="{'': is_valid }"
                                                                id="company">
                                                        </div>
                                                        <div class="form-group col-lg-6 col-md-6 col-xs-6">
                                                            <label for="" class="input-label">RFC</label>
                                                            <input (input)="onCapture('rfc')" type="text"
                                                                class="form-control input-form-height"
                                                                formControlName="rfc" [ngClass]="{'': is_valid }"
                                                                id="rfc" #rfc>
                                                        </div>
                                                    </div>
                                                    <h4 style="color: #0275d8; font-weight: 700; font-size: 14px; margin-top: 0;"
                                                        class="lexend">
                                                        Cuéntanos sobre ti:
                                                    </h4>
                                                    <div class="container-fluid row"
                                                        style="padding-left: 0px; padding-right: 0px;">
                                                        <div class="form-group col-lg-6 col-md-6 col-xs-6">
                                                            <label for="" class="input-label">Giro de tu negocio</label>
                                                            <select name="line_of_business" id="line_of_business"
                                                                class="form-control input-form-height"
                                                                (change)="onChangeLineOfBusiness()">
                                                                <optgroup class="label-users-field">
                                                                    <option style="display:none;"></option>
                                                                    <option *ngFor="let regimen of lineOfBusinessList"
                                                                        [value]="regimen.id"
                                                                        [attr.data-value]="regimen.id"
                                                                        [attr.data-id]="regimen.id">
                                                                        {{regimen.value}}
                                                                    </option>
                                                                </optgroup>>
                                                            </select>
                                                        </div>
                                                        <div class="form-group col-lg-6 col-md-6 col-xs-6">
                                                            <label for="" class="input-label">Número de
                                                                contadores</label>
                                                            <input (input)="onCapture('company')" type="number"
                                                                #number_of_users class="form-control input-form-height"
                                                                [ngClass]="{'': is_valid }" id="number_of_users" min="1"
                                                                max="99" value="1">
                                                        </div>
                                                    </div>
                                                    <div class="container-fluid row"
                                                        style="padding-left: 0px; padding-right: 0px;">
                                                        <div class="form-group col-lg-6 col-md-6 col-xs-6">
                                                            <label for="" class="input-label">¿Cómo escucho de
                                                                nosotros?</label>
                                                            <select name="pipedrive_referrence" id="pipedrive_reference"
                                                                class="form-control input-form-height"
                                                                (change)="onChangePipedriveReference()">
                                                                <optgroup class="label-users-field">
                                                                    <option style="display:none;"></option>
                                                                    <option
                                                                        *ngFor="let reference of pipedriveReferenceList"
                                                                        [value]="reference.id"
                                                                        [attr.data-value]="reference.value"
                                                                        [attr.data-id]="reference.id">
                                                                        {{reference.value}}
                                                                    </option>
                                                                </optgroup>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="container-fluid row"
                                                        style="padding-left: 0px; padding-right: 0px;">
                                                        <div class="col-lg-1" style="padding-right: 0%;">
                                                            <input type="checkbox" name="" style="width:15px;"
                                                                id="accept_tyc" (change)="acceptedTyC = !acceptedTyC">
                                                        </div>
                                                        <div class="col-lg-11" style="padding-left: 0%;">
                                                            <label class="acept-terms-and-conditions">
                                                                Acepto los
                                                                <a href="assets/TERMINOS Y CONDICIONES CONTALINK 08_23.pdf"
                                                                    target="_blank" rel="noopener noreferrer"
                                                                    (change)="acceptedTyC = !acceptedTyC">
                                                                    términos y condiciones
                                                                </a>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="container-fluid row"
                                                        style="padding-left: 0px; padding-right: 0px;">
                                                        <div class="col-lg-1" style="padding-right: 0%;">
                                                            <input type="checkbox" name="" style="width:15px;"
                                                                id="acceptedEmailPromotional"
                                                                (click)="changeAcceptedPromotionalEmail()" checked>
                                                        </div>
                                                        <div class="col-lg-11" style="padding-left: 0%;">
                                                            <label class="acept-terms-and-conditions">
                                                                Acepto recibir correos electrónicos de comunicados y
                                                                promociones.
                                                            </label>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- BOTON SIGUIENTE -->
                                    <div class="col-lg-12">
                                        <div>
                                            <button mat-button (click)="MichoacanPostSlack();"
                                                type="button"
                                                [disabled]="!acceptedTyC"
                                                [ngClass]="{ 'active-button': acceptedTyC, 'inactive-button': !acceptedTyC }"
                                                style="font-size: .9em; color: white; border-radius: 15px / 50%; 
                                                    width: 100%; background-color: #0275d8;">
                                                Crear cuenta
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-1 deleted-movil">

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </form>
    </div>
    <div class="col-lg-1">

    </div>
</div>