import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { EnvironmentService } from './environment.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MixPanelService {
  private mixpanelSecondary: mixpanel.Mixpanel;
  constructor(private env: EnvironmentService){
    this.mixpanelSecondary = null;
  }
  _env: string = this.env._env;
  // Nombre que se sugería para el archivo: MixpanelService.service.ts
  // https://medium.com/@jeffreyyy/mixpanel-for-angular-e0c0d8c08d3a
  /**
   * Initialize mixpanel.
   *
   * @param {string} userToken
   * @memberof MixpanelService
   */
  init(userToken: string): void {
    mixpanel.init('dc30d035a0e2db62bbe288d2c68a5a58');
    mixpanel.identify('ventas_landing');
  }

  referredInit(userToken: string): void {
    this.mixpanelSecondary = mixpanel.init('93333091668f41d11dabb43b40b5e0d6', { debug: true }, 'contalink_angular') as mixpanel.Mixpanel;
  }

  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   * @memberof MixpanelService
   */
  track(id: string, action: any = {}, url: string): void {
    if (this._env == 'prod'){
      this.init(null);
      mixpanel.track(id, action);
    }
  }

  referredTrack(id: string, action: any = {}, url: string): void {
    if (this._env == 'prod'){
      this.referredInit(null);
      this.mixpanelSecondary.track(id, action);
    }
  }

}