<br><br>


<!--
<div class="loading-div" id="loading-div-section" style="text-align:center;" *ngIf="LSCobrado == false && LSError == false">
  <br><br>
  <img src="https://amjuzgadoras.org/includes/images/iconos/loanding.gif"
    style="width:5%;"/>
    <br><br>
  <p>Espere mientras procesamos su pago...</p>
</div>
-->
<section class="card-payment" id="card-payment-section">
  <mat-card>
    <mat-card-title>{{titleLastStep}}</mat-card-title>
    <div>
      <div *ngIf="isRetry == true && LSError == true" align="center" id="stripe-retry-btn">
        <br>
        <p style="text-align:left;">
            Ha habido un error al procesar tu pago. {{textLSStripeMessage}}<br>
            {{textLSMainMessage}}<br>
            Favor de comunicarse con nuestro equipo vía info@contalink.com o por Whatsapp al +52 811 910 7569
            <br><br>
            También puedes intentar con otra tarjeta:<br><br>
        </p>
        <a class="btn_ingresar_a_mi_cuenta" href="https://signup.contalink.com/retry?setup_intent_client_secret={{textRetrySecret}}">INGRESAR OTRA TARJETA</a><br><br>
      </div> 
      <div *ngIf="isRetry == false && LSError == true" align="center" id="stripe-retry-btn">
        <br>{{textLSMainMessage}}<br>
      </div>     
      <div *ngIf="LSCobrado == false && LSError == false" align="center" style="padding-top:3em;padding-bottom:1em;">
        <img src="https://c.tenor.com/I6kN-6X7nhAAAAAi/loading-buffering.gif"
        style="width:5%;"/>
      </div>
      <div *ngIf="LSCobrado">
        <p>
          <b><h4>Pasos para comenzar</h4></b>
          <b>1.- Acceder a Contalink desde su navegador web</b>
          <br>Su usuario para ingresar al sistema es: {{textLSUserName}}
        </p>
        <br>
        <div align="center"><a class="btn_ingresar_a_mi_cuenta" href="https://app.contalink.com/">INGRESAR A MI CUENTA</a></div>
        <br>
        <b>2.- Conviertase en un experto</b>
        <p>Le invitamos a ver nuestro video de <a href="https://www.notion.so/contalink/Gu-a-R-pida-Principal-33841b5682bf48a380433fc7c7fd368e">introducción y primeros pasos</a>, con ello podrá conocer como funciona Contalink.
          Para dudas y soporte inicial contactar a:
          <br><b>{{textLSOnBoardingAgent}}</b>
          <br>Correo: {{textLSOnBoardingEmail}}
          <br><br>
          Si desea aplicar un ajuste a su suscripción, dar de baja su cuenta o modificar su facturación le agradecemos pueda notificar por correo electrónico a soporte@contalink.com
          <br>
          ¡Bienvenido a Contalink!
        </p>
      </div>
    </div>
  </mat-card>
</section>
<br><br>
<!--
<h1>PAGOS</h1>
<h2>Cliente: {{bp_name}}</h2>
<h2>Total: {{order_total}}</h2>
-->